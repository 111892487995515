'use client';

import React from 'react';
import useCreateCustomerMutation, {
	CreateCustomerMutationData,
	CreateCustomerMutationDataSchema,
	UseCreateCustomerMutationProps,
} from '@mgp-fe/shared/core-api/mutations/user/customer/create.ts';
import {FormProvider, useForm} from 'react-hook-form';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {zodResolver} from '@hookform/resolvers/zod';
import {TextInput} from '@mgp-fe/shared/ui/form/TextInput.tsx';
import {Alert, AlertDescription, AlertTitle} from '@mgp-fe/shared/ui/alert.tsx';
import PhoneNumberInput from '@mgp-fe/shared/ui/form/PhoneNumberInput.tsx';
import {useGetRecaptchaToken} from '@mgp-fe/shared/hooks/useGetRecaptchaToken.ts';
import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';
import Modal from '@mgp-fe/shared/ui/Modal.tsx';
import PrivacySettingsForm from '@mgp-fe/shared/modules/account/components/privacy-settings/PrivacySettingsForm.tsx';
import useNotify from '@mgp-fe/shared/ui/notifications/use-notify.ts';
import GoogleSignButton from "@mgp-fe/shared/modules/auth/components/GoogleSignButton.tsx";

export default function RegisterCustomerForm({onSuccess, onSubmit, onSubmitStatus, defaultValues}: RegisterCustomerFormProps) {
	const recaptchaToken = useGetRecaptchaToken('signup');
	const {notifyError} = useNotify();
	const form = useForm<CreateCustomerMutationData>({
		resolver: zodResolver(CreateCustomerMutationDataSchema),
		defaultValues,
	});
	const registerMutation = useCreateCustomerMutation({
		onSuccess: (data, variables, context) => {
			onSuccess?.(data, variables, context);
		},
	});

	const signUpHandler = async (d: CreateCustomerMutationData) => {

		if (!d.contactConsent?.transactionalEmails && !d.contactConsent?.transactionalSms && !d.contactConsent?.transactionalCalls) {
			notifyError('At least 1 transactional option is necessary');
			displayModal.on();
			return;
		}

		if(onSubmit) {
			onSubmit(d);
			return;
		}

		registerMutation.mutate({...d, recaptchaToken: await recaptchaToken()});
	};

	const displayModal = useToggle({initialState: false});

	return <FormProvider {...form}>
		<form
			onSubmit={form.handleSubmit(signUpHandler)}
			className='flex flex-col horizontal-labels w-full horizontal-labels'>

			<TextInput label='Your Name' type='text' name='name'/>
			<PhoneNumberInput label='Phone' type='tel' name='phone'/>
			<TextInput label='E-mail' type='email' name='email'/>
			<TextInput label='Password' type='password' name='password'/>

			<label className='!flex-row !items-center !gap-small'>
				<input type='checkbox' onChange={event => {
					form.setValue('contactConsent.transactionalEmails', event.currentTarget.checked);
					form.setValue('contactConsent.transactionalCalls', event.currentTarget.checked);
					form.setValue('contactConsent.transactionalSms', event.currentTarget.checked);
					form.setValue('contactConsent.marketingEmails', event.currentTarget.checked);
					form.setValue('contactConsent.marketingCalls', event.currentTarget.checked);
					form.setValue('contactConsent.marketingSms', event.currentTarget.checked);
				}} required={true}/> I accept conditions.
			</label>
			<Button onClick={displayModal.on} type='button' variant='link' size='sm' className='w-fit pl-0'>
				Set up contact settings
			</Button>

			<Modal isOpen={displayModal.state} onClose={displayModal.off} dialogPanelClassName='max-w-7xl'
			>
				<div className='overflow-hidden max-w-[50rem] w-full mx-auto'>
					{/*//TODO fix form*/}
					<PrivacySettingsForm form={form}/>
				</div>
				<Button type='submit'
					className='mt-medium md:mt-large w-full md:w-fit float-right'
					state={onSubmitStatus || registerMutation.status}
					onClick={displayModal.off}
					icon={<FontAwesomeIcon icon='user-plus' className='text-base'/>}>
					Save
				</Button>
			</Modal>

			<Button type='submit'
				className='mt-8'
				state={onSubmitStatus || registerMutation.status}
				icon={<FontAwesomeIcon icon='user-plus' className='text-base'/>}>
				Register
			</Button>

			<div className='flex flex-row mt-8 gap-2 justify-center'>
				<GoogleSignButton type='icon' size='large' shape='circle' theme='filled_blue'/>
			</div>
			{registerMutation.isError && <Alert variant='destructive' className='mb-0'>
				<AlertTitle>
					{registerMutation.error?.response?.data['hydra:title'] || 'Error'}
				</AlertTitle>
				<AlertDescription>
					{registerMutation.error?.response?.data['hydra:description'] || registerMutation.error?.response?.data['detail'] || 'Unknown error'}
				</AlertDescription>
			</Alert>}
		</form>
	</FormProvider>;
}

interface RegisterCustomerFormProps {
	onSubmit?: (data: CreateCustomerMutationData) => void;
	onSubmitStatus?: 'idle' | 'loading' | 'success' | 'error';
	onSuccess?: UseCreateCustomerMutationProps['onSuccess'];
	defaultValues?: CreateCustomerMutationData;
}